// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dekujeme-newsletter-js": () => import("./../../../src/pages/dekujeme-newsletter.js" /* webpackChunkName: "component---src-pages-dekujeme-newsletter-js" */),
  "component---src-pages-dekujeme-vam-js": () => import("./../../../src/pages/dekujeme-vam.js" /* webpackChunkName: "component---src-pages-dekujeme-vam-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontaktujte-nas-index-js": () => import("./../../../src/pages/kontaktujte-nas/index.js" /* webpackChunkName: "component---src-pages-kontaktujte-nas-index-js" */),
  "component---src-pages-o-nas-index-js": () => import("./../../../src/pages/o-nas/index.js" /* webpackChunkName: "component---src-pages-o-nas-index-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/BlogIndex.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-person-detail-page-js": () => import("./../../../src/templates/PersonDetailPage.js" /* webpackChunkName: "component---src-templates-person-detail-page-js" */),
  "component---src-templates-service-detail-page-js": () => import("./../../../src/templates/ServiceDetailPage.js" /* webpackChunkName: "component---src-templates-service-detail-page-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/SinglePost.js" /* webpackChunkName: "component---src-templates-single-post-js" */)
}

